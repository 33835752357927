import { Store } from "./src/redux/store";

export const onClientEntry = () => {
  window.onload = () => {
    // Updated variables
    window.globalDnsDeployment = false;
    window.claripHost = 'purdue.clarip.com';
    window.claripCdnHost = 'cdn.clarip.com';
    window.clientName = 'purdue'; // CHANGED: Updated client name from 'purdue1' to 'purdue'

    // Additional settings
    window.dnsControllerType = 'option-2';
    window.dnsSubmissionUrl = 'https://purdue.clarip.com/dsr/success?brand=HYSINGLA';
    window.standardDsrFormUrl = 'https://purdue.clarip.com/dsr/create?brand=HYSINGLA&type=3';
    window.createDoNotSellLink = 0;
    window.doNotSellLinkSelector = '#clarip-do-not-sell-link';
    window.doNotSellCookieName = 'clarip_dns_cookie';
    window.doNotSellCookieValue = 1;
    window.doNotSellCookieExpirationAge = 1825;
    window.enableEnforcementScope = false;

    // Load scripts and styles for consent prompt
    const link = document.createElement('link');
    link.rel = "stylesheet";
    link.type = "text/css";
    // CHANGED: Updated the href to reflect the new client name
    link.href = 'https://cdn.clarip.com/purdue/donotsell/assets/css/donotsell-extended.min.css';
    document.head.appendChild(link);

    const blockScript = document.createElement('script');
    // CHANGED: Updated the src to reflect the new client name
    blockScript.src = 'https://cdn.clarip.com/purdue/donotsell/assets/js/block-3rd-parties.min.js';
    document.body.appendChild(blockScript);

    const dnsBlockScript = document.createElement('script');
    // CHANGED: Updated the src to reflect the new client name
    dnsBlockScript.src = 'https://cdn.clarip.com/purdue/donotsell/assets/js/donotsell-block.min.js';
    document.body.appendChild(dnsBlockScript);

    // Function to set the consent cookie
    function setConsentCookie() {
        console.log("Consent button clicked. Setting cookie...");

        const consentCookie = 'hysinglaer_clarip_consent';
        const currentDomain = window.location.hostname; // Dynamically get the current domain
        const cookieValue = 'true'; // User has given consent
        const expiryDays = 365; // Expiry in days

        const expiryDate = new Date();
        expiryDate.setTime(expiryDate.getTime() + (expiryDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + expiryDate.toUTCString();

        document.cookie = consentCookie + "=" + cookieValue + ";" + expires + ";domain=" + currentDomain + ";path=/";
    }

    // Attach event listener to '.cc-allow' button
    document.addEventListener('DOMContentLoaded', () => {
      const consentButton = document.querySelector('.cc-allow');
      if (consentButton) {
        console.log("Attaching event listener to '.cc-allow' button");
        consentButton.addEventListener('click', setConsentCookie);
      } else {
        console.log("'.cc-allow' button not found");
      }
    });

    // Dispatch DOMContentLoaded event
    setTimeout(() => {
      window.dispatchEvent(new Event('DOMContentLoaded'));
    }, 1000);
  };
};

export const wrapRootElement = Store;
